@import "bootstrap-variables";
// Variable overrides

// core overrides
$gc-yellow:                         #ffc82e;
$gc-yellow-low:                     #ffe396;
$gc-blue:                           #001e96;
$gc-blue-low:                       lighten($gc-blue, 10%);

// navbar
$navbar-bg:                           $gc-yellow !default;
$navbar-brand-bg:                     $gc-yellow !default;
$navbar-border: (
  bottom: (
    size:                             3px,
    style:                            solid,
    color:                            $gc-yellow-low
  )
)  !default;
$navbar-brand-border: (
  bottom: (
    size:                             2px,
    style:                            solid,
    color:                            $gc-yellow-low
  )
) !default;

$navbar-brand-logo:                   url('../assets/img/brand/logo.svg') !default;
$navbar-brand-logo-size:              180px auto !default;
$navbar-brand-width:                  200px !default;

$navbar-brand-minimized-logo:         url('../assets/img/brand/gc-logo-symbol.png') !default;
$navbar-brand-minimized-bg:           $gc-blue !default;

$navbar-color:                        $gc-blue;
$navbar-hover-color:                  $gc-blue;
$navbar-active-color:                 $gc-blue-low;
$navbar-disabled-color:               $gc-yellow-low;

// Sidebar
$sidebar-bg:                          #ffffff;
$sidebar-borders:                     none !default;


// Sidebar Navigation
$sidebar-color:                       $gray-600;
$sidebar-nav-color:                   $gray-600;
$sidebar-nav-link-color:              $gray-600;
$sidebar-nav-link-active-color:       $gc-blue;

$sidebar-nav-link-hover-color:        $gc-blue;
$sidebar-nav-link-hover-bg:           $gc-yellow;
$sidebar-nav-link-hover-icon-color:   $gray-600;

$sidebar-nav-link-active-color:       $gc-blue;
$sidebar-nav-link-active-bg:          $gc-yellow;
$sidebar-nav-link-active-icon-color:  $gc-blue;

$sidebar-nav-dropdown-bg:             lighten($gc-yellow-low, 3%);
$sidebar-nav-dropdown-color:          $gc-blue;
$sidebar-nav-dropdown-indicator-color:$gc-blue;
