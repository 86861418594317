// Here you can add other styles
html, body {
	cursor: default;
}

.input-group-append .btn{
	z-index:0;
}

// table {
//     width: 716px; /* 140px * 5 column + 16px scrollbar width */
//     border-spacing: 0;
// }

// tbody, thead tr { display: block; }

// tbody {
//     height: 350px;
//     overflow-y: auto;
//     overflow-x: hidden;
// }

// tbody td, thead th {
//     width: 140px;
// }

// thead th:last-child {
//     width: 156px; /* 140px + 16px scrollbar width */
// }
