.fullPageLoader {
	height: 100%;
	width: 100%;
	z-index: 2147483647;
	background: rgba(255, 255, 255, 0.75);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&__message {
		font-size: 2em;
		font-weight: bold;
		margin-top: 1em;
	}

	&__icon {
	}

	&__content {
		height: '100vh'
	}
}
