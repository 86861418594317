.app {

  &-header {
    padding-right: 10px;
  }

  &-footer {
    justify-content: center;
  }
}

.dev-alert {
	background: red;
	color:white;
	text-align: center;
	position: fixed;
	width: 100%;
	top:0;
	z-index: 100;
}
